<template>
  <div>
    <b-overlay :show="show" rounded="sm" no-fade>
      <b-card-code title="Informasi Data Aset">
        <b-row class="mt-2 mb-2" align="end">
          <b-col md="2">
            <b-form-input
              id="name"
              v-model="name"
              placeholder="Cari Nama Aset"
              @input="changeStatus()"
            />
          </b-col>
          <b-col md="2">
            <b-form-input
              id="name"
              v-model="contact_name"
              placeholder="Cari Nama Kontak"
              @input="changeStatus()"
            />
          </b-col>
          <b-col md="2">
            <v-select
              v-model="payment_status"
              :options="statusOptions"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              placeholder="Pilih Status"
              @input="changeStatus()"
            />
          </b-col>
          <b-col md="3" align="start">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              @click="resetFilter()"
            >
              Reset
            </b-button>
          </b-col>

          <b-col align="end" md="3">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="formTambah()"
            >
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span class="align-middle">Tambah</span>
            </b-button>
          </b-col>
        </b-row>

        <!-- table -->
        <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :search-options="{
            enabled: false,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
          }"
          @on-sort-change="onSortChange"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'due_date'">
              {{ props.row.due_date.substring(0, 10) }}
            </span>

            <span v-else-if="props.column.field === 'payment_status'">
              <span v-if="props.row.payment_status === 'LUNAS'">
                <b-badge variant="success"> LUNAS </b-badge>
              </span>

              <span v-if="props.row.payment_status === 'BELUM_LUNAS'">
                <b-badge variant="warning"> BELUM LUNAS </b-badge>
              </span>

              <span v-if="props.row.payment_status === 'CANCEL'">
                <b-badge variant="danger"> BATAL </b-badge>
              </span>
            </span>

            <span v-else-if="props.column.field === 'create_time'">
              {{ props.row.create_time.substring(0, 10) }}
            </span>

            <span v-else-if="props.column.field === 'purchase_date'">
              {{ props.row.purchase_date.substring(0, 10) }}
            </span>

            <span v-else-if="props.column.field === 'length'">
              {{ props.row.length }} x {{ props.row.width }}
            </span>

            <span v-else-if="props.column.field === 'lineNumber'">
              {{ paging.page * 10 + (props.index + 1) }}.
            </span>

            <span v-else-if="props.column.field === 'price'">
              Rp.
              {{
                props.row.price
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
              }}
            </span>

            <span v-else-if="props.column.field === 'down_payment_amount'">
              Rp.
              {{
                props.row.down_payment_amount
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
              }}
            </span>

            <span v-else-if="props.column.field === 'total_price'">
              Rp.
              {{
                props.row.total_price
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
              }}
            </span>

            <span v-else-if="props.column.field === 'total_paid'">
              Rp.
              {{
                props.row.total_paid
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
              }}
            </span>

            <span v-else-if="props.column.field === 'total_unpaid'">
              Rp.
              {{
                props.row.total_unpaid
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
              }}
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                v-b-tooltip.hover.v-primary
                title="Detail"
                class="btn-icon mr-2"
                @click="formDetail(props)"
              >
                <feather-icon icon="SearchIcon" class="cursor-pointer" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                v-b-tooltip.hover.v-warning
                title="Ubah"
                class="btn-icon mr-2"
                @click="formEdit(props)"
              >
                <feather-icon icon="EditIcon" class="cursor-pointer" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                v-b-tooltip.hover.v-danger
                title="Hapus"
                class="btn-icon"
                @click="deleteInfo(props)"
              >
                <feather-icon icon="Trash2Icon" class="cursor-pointer" />
              </b-button>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->

          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="paging.size"
                  :options="paging.perPageOptions"
                  class="mx-1"
                  @input="handlePageChange"
                />
                <span class="text-nowrap">
                  of {{ paging.total_elements }} entries
                </span>
              </div>

              <div>
                <b-pagination
                  :value="paging.page + 1"
                  :total-rows="paging.total_elements"
                  :per-page="paging.size"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handleChangePage"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card-code>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import axios from "@axios";
import vSelect from "vue-select";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BPagination,
  BFormFile,
  BOverlay,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BMedia,
  BAvatar,
  BMediaBody,
  BMediaAside,
  BAvatarGroup,
  BImg,
  BInputGroupPrepend,
  BCard,
  BCardText,
  BCardBody,
  BDropdownItem,
  BDropdown,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";

export default {
  components: {
    BCardCode,
    VBTooltip,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BPagination,
    VueGoodTable,
    BFormFile,
    BOverlay,
    vSelect,
    BFormSelect,
    BBadge,
    BFormTextarea,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
    BImg,
    BInputGroupPrepend,
    BCard,
    BCardBody,
    BCardText,
    BDropdownItem,
    BDropdown,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      //here
      file: null,

      required,
      email,

      log: [],
      dir: false,
      pages: ["5", "10", "20", "30"],

      paging: {
        page: 0,
        total_pages: 0,
        size: 10,
        total_elements: 0,
        perPageOptions: [10, 25, 50, 100],
      },

      columns: [
        {
          label: "No",
          field: "lineNumber",
          thClass: "text-center",
          tdClass: "text-center",
          width: "70px",
        },
        {
          label: "Nama Aset",
          field: "name",
          thClass: "text-center",
        },
        {
          label: "Jatuh Tempo",
          field: "due_date",
          thClass: "text-center",
        },
        {
          label: "Nama Kontak",
          field: "contact_name",
          thClass: "text-center",
        },
        {
          label: "Harga",
          field: "price",
          thClass: "text-center",
        },
        {
          label: "DP",
          field: "down_payment_amount",
          thClass: "text-center",
        },
        {
          label: "Status",
          field: "payment_status",
          thClass: "text-center",
        },
        // {
        //   label: "Angsuran Ke",
        //   field: "",
        //   thClass: "text-center",
        // },
        {
          label: "Total Masuk",
          field: "total_paid",
          thClass: "text-center",
        },
        {
          label: "Kekurangan",
          field: "total_unpaid",
          thClass: "text-center",
        },

        {
          label: "Aksi",
          field: "action",
          thClass: "text-center",
          tdClass: "text-center",
          width: "200px",
        },
      ],
      rows: [],

      tanggal_filter: "",

      configs: {
        mode: "range",
        allowInput: {
          allowInput: true,
          dateFormat: "Y-m-d",
        },
      },

      formDisabled: {
        purchase_date: "",
        buyer_name: "",
        facility: "",
        payment_status: "",
        kavling_name: "",
      },

      formSubmit: {
        name: "",
        kavling_id: "",
        price: "",
        length: "",
        width: "",
        is_booked: false,
        note: "",
        facility: "",
      },

      kavlingOptions: [],
      marketingOptions: [],
      statusOptions: [],

      // old delete
      searchTerm: "",

      name: "",
      payment_status: "",
      contact_name: "",

      sort: "",

      create_time: "",

      show: false,
      aksi: "",
      id_kavling: "",

      nama: "",

      selected: null,
    };
  },

  beforeMount() {
    this.getData(this.paging.size, this.paging.page);
    this.getKavling();
    this.getMarketing();
    this.getPaymenStatusBlock();
  },

  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
  },

  created() {
    //here
  },

  methods: {
    getData(size, page) {
      this.show = true;

      let search0 = "";
      if (this.name) {
        search0 = "&name=" + this.name;
      } else {
        search0;
      }

      let search1 = "";
      if (this.payment_status) {
        search1 = "&payment_status=" + this.payment_status;
      } else {
        search1;
      }

      let search2 = "";
      if (this.contact_name) {
        search2 = "&contact_name=" + this.contact_name;
      } else {
        search2;
      }

      return new Promise(() => {
        axios
          .get(
            "v1/asset/all?" +
              "page=" +
              page +
              "&size=" +
              size +
              "&sort_by=name&sort_dir=1" +
              search0 +
              search1 +
              search2
          )
          .then((res) => {
            this.rows = res.data.content;

            this.paging.page = res.data.page;
            this.paging.size = res.data.size;
            this.paging.total_elements = res.data.total_elements;
            this.paging.total_pages = res.data.total_pages;

            this.show = false;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
            }
            this.show = false;
          });
      });
    },

    formTambah() {
      this.$router.push("/aset/form");
    },

    formEdit(props) {
      this.$router.push("/aset/form/" + props.row._id);
    },

    formDetail(props) {
      this.$router.push("/aset/form/" + props.row._id + "/detail");
    },

    deleteInfo(props) {
      this.$swal({
        title: "Peringatan",
        text: "Apakah yakin akan menghapus aset ? Menghapus aset akan menghapus semua pembayaran didalamnya",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Hapus!",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteApi(props);
        }
      });
    },

    deleteApi(props) {
      return new Promise(() => {
        axios
          .delete("v1/asset/" + props.row._id)
          .then((res) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Hapus Data Berhasil`,
                icon: "CheckCircleIcon",
                variant: "success",
                text: `Hapus Data Aset Berhasil`,
              },
            });
            this.getData(this.paging.size, this.paging.page);
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
            }
            this.show = false;
          });
      });
    },

    changeStatus() {
      const page = (this.paging.page = 0);
      this.getData(this.paging.size, page);
    },

    getKavling() {
      return new Promise(() => {
        axios.get("v1/kavling/combo").then((res) => {
          this.kavlingOptions = res.data;
        });
      });
    },

    getMarketing() {
      return new Promise(() => {
        axios.get("v1/employee/combo/marketing").then((res) => {
          this.marketingOptions = res.data;
        });
      });
    },

    getPaymenStatusBlock() {
      return new Promise(() => {
        axios.get("v1/asset/combo_payment_status").then((res) => {
          this.statusOptions = res.data;
        });
      });
    },

    pilihKavling(event) {
      this.formSubmit.kavling_id = event;
    },

    resetFilter() {
      this.kavling_id = "";
      this.marketing_id = "";
      this.payment_status = "";
      this.block_name = "";
      this.owner_name = "";
      const page = (this.paging.page = 0);
      this.getData(this.paging.size, page);
    },

    handleSearch(searching) {
      const page = (this.paging.page = 0);
      this.getData(this.paging.size, page);
    },
    handleChangePage(value) {
      const page = value - 1;
      this.getData(this.paging.size, page);
    },
    handlePageChange(active) {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },
    onSortChange(params) {
      this.sort = `${params[0].field}`;
      this.getData(this.paging.size, this.paging.page);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
